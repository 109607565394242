<script setup lang="ts">
import type { DialogBreakpoints } from 'primevue/dialog';
import Button from './button.vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import { trackingMethods } from '~/plugins/analytics.client';
import Dialog from 'primevue/dialog';
import { dialogPassthrough } from '~/components/passthroughs/dialog';
interface IModalProps {
  title?: string;
  breakpoints?: DialogBreakpoints;
  width?: string | number | undefined;
  active?: boolean;
  contentType?: 'form' | 'content';
  displayBackground?: boolean;
  closeModalOption?: boolean;
  displayBorder?: boolean;
  headerIcon?: Component;
  headerIconClass?: string;
  iconTooltip?: string;
  displayModal?: boolean; //used to close mini deposit modal
}
const { $t }: any = useNuxtApp();
const emits = defineEmits(['closeModal']);

const props = withDefaults(defineProps<IModalProps>(), {
  title: '',
  contentType: 'form',
  displayBackground: true,
  closeModalOption: true,
  displayBorder: true,
  headerIcon: null,
  headerIconClass: '',
  iconTooltip: '',
  active: false,
});

const openModal = ref<boolean>(false);
const toggleModal = () => {
  if (!props.active) {
    openModal.value = true;
  }
};
const closeModal = () => {
  openModal.value = false;
  unlockBodyScroll();
  const registerModal = $t('jpc-register');
  if (props.title === registerModal) {
    trackingMethods.registerAbandoned();
  }
};
const width = computed(() => {
  if (props.width) return props.width;
  if (props.contentType === 'form') return '480px';
  if (props.contentType === 'content') return '800px';
});

const classes = (): string => {
  const baseClasses = 'hide-scrollbar overflow-hidden';
  return props.displayBackground ? ' ' + baseClasses : baseClasses;
};

const contentClasses = computed(() => {
  const borderless = props.displayBorder ? '' : ' borderless';
  const transparent = props.displayBackground ? '' : 'bg-transparent';
  return borderless + ' ' + transparent;
});

watchEffect(() => {
  if (props.active === true) {
    switch (props.title) {
      // send GA tracking method for register modal loaded
      case $t('jpc-register'):
        trackingMethods.registerLoaded();
        break;
      // send GA tracking method for search modal loaded
      case $t('search-games'):
        trackingMethods.toggleSearch();
        break;
      default:
        break;
    }
  }
});

watch(
  () => props.displayModal,
  (val, oldVal) => {
    openModal.value = val ?? false;
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <div @click="toggleModal">
      <slot name="modal-activator"></slot>
    </div>
    <Dialog
      :modal="true"
      :closable="false"
      :draggable="false"
      :breakpoints="props.breakpoints"
      :style="{ maxWidth: 'calc(100vw - 0.5rem)', width }"
      :visible="props.active ? props.active : openModal"
      :class="classes"
      :content-class="contentClasses"
      style="max-height: 90vh"
      :show-header="!!props.title"
      :dismissable-mask="true"
      append-to="body"
      :pt="dialogPassthrough"
      :block-scroll="true"
      @show="lockBodyScroll"
      @hide="unlockBodyScroll"
    >
      <template v-if="props.title" #header>
        <div class="flex flex-row mx-2 items-center">
          <component
            :is="props.headerIcon"
            v-if="props.headerIcon"
            :class="props.headerIconClass"
            :tooltip="props.iconTooltip"
          />
        </div>
        <div class="flex flex-row justify-between w-full items-center p-2">
          <div class="h-fit text-xl font-bold text-base-priority capitalize">
            {{ title }}
          </div>
          <Button
            v-if="props.closeModalOption"
            element-name="close-modal"
            type="quarternary"
            size="medium"
            rounding="md"
            @click="($emit('closeModal'), closeModal())"
          >
            <XMarkIcon />
          </Button>
        </div>
      </template>
      <slot name="modal-content" />

      <template v-if="!!$slots['footer-content']" #footer>
        <slot name="footer-content"></slot>
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss">
.dark {
  .p-dialog .p-dialog-footer {
    background: #131217 !important;
    padding: 0px;
  }
}

.p-dialog {
  box-shadow: none !important;
  border-radius: 8px;
  position: absolute;
  top: 105px;

  @media screen and (min-width: 641px) and (max-width: 1366px) {
    top: 50px;
  }
  @media (max-width: 640px) {
    top: 5px !important;
  }
  & .p-dialog-header {
    background: #eceff6 !important;
    padding: 12px 15px !important;
    border: 1px solid #dae0ed !important;
    border-bottom: 0;
    .dark & {
      background-color: #121417 !important;
      border-color: #464f60 !important;
      border-bottom: 0;
    }

    & h2 {
      font-family: Inter;
      font-size: 16px;
      margin-top: 0.3rem;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.5;
      text-align: left;
      color: #2b303b;

      .dark & {
        color: #ffffff;
      }
    }

    & .p-button.p-button-icon-only {
      width: 32px;
    }
  }

  & .p-dialog-content {
    background: #fff;
    padding: 0px 0px 0px 0px !important;
    border: 1px solid var(--light-light-400, #dae0ed);
    border-top: none !important;

    .dark & {
      border: 1px solid var(--dark-dark-500, #464f60);
    }

    &.borderless {
      border: none !important;
    }
    &::-webkit-scrollbar {
      display: none !important;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
    .dark & {
      background: #1d2129;
    }
  }

  & .p-dialog-footer {
    background: #f4f4f6 !important;
  }
}
</style>
